export const ComponentContentTypes = {
  Section: 'Section',
  SeoStandard: 'SeoStandard',
  Global: 'Global',
  NewsListing: 'NewsListing',
  GamesListing: 'GamesListing',
  HeaderArticle: 'HeaderArticle',
  BodyArticle: 'BodyArticle',
  ExploreMoreOrganisms: 'ExploreMore',
  Subscribe: 'Subscribe',
  BuyGameContainer: 'BuyGameContainer',
  Mashthead: 'Mashthead',
  MessageCard: 'MessageCard',
  ExploreNewUniverse: 'ExploreNewUniverse',
  HorizontalScrollingContainerMediaGallery: 'HorizontalScrollingContainerMediaGallery',
  MediaGallery: 'MediaGallery',
  HomePageGamesListing: 'HomePageGamesListing',
  HomePageNewListing: 'HomePageNewListing',
  AgeRatingPopup: 'AgeRatingPopup',
  SuggestionCard: 'SuggestionCard',
  MastheadImage: 'MastheadImage',
  LocateUs: 'LocateUs',
  JobOpportunity: 'JobOpportunity',
};

export const ComponentContentTypesNewsListing = {
  FeaturedCardCarousel: 'FeaturedCardCarousel',
  ThumbnailCardCarousel: 'ThumbnailCardCarousel',
  ThumbnailCardWithPublicDate: 'ThumbnailCardWithPublicDate',
  ScrollingNewsFilter: 'ScrollingNewsFilter',
  ScrollingFeaturedGenreGameContainer: 'ScrollingFeaturedGenreGameContainer',
  ScrollingPopularGameContainer: 'ScrollingPopularGameContainer',
  ScrollingTwoGenreGames: 'ScrollingTwoGenreGames',
  AdsContainer: 'AdsContainer',
};

export const ComponentContentTypesBlogListing = {
  BlogFilter: 'BlogFilter',
  BlogListGoodToKnow: 'BlogListGoodToKnow',
};

export const ComponentContentTypesSupportFAQ = {
  SupportFaq: 'SupportFaq',
};

export const ComponentContentTypesGamesListing = {
  // store
  EStoreBestsellerHome: 'EStoreBestsellerHome',
  MashtheadStore: 'MashtheadStore',
  SaleEvent: 'SaleEvent',
  EStoreProductDetailRecently: 'EStoreProductDetailRecently',
  EStoreNewArrivalHome: 'EStoreNewArrivalHome',
  PlatformListing: 'PlatformListing',
  // end
  FeaturedCardCarousel: 'FeaturedCardCarousel',
  ThumbnailCardCarousel: 'ThumbnailCardCarousel',
  ThumbnailCardWithPublicDate: 'ThumbnailCardWithPublicDate',
  ScrollingNewsFilter: 'ScrollingNewsFilter',
  ScrollingFeaturedGenreGameContainer: 'ScrollingFeaturedGenreGameContainer',
  ScrollingPopularGameContainer: 'ScrollingPopularGameContainer',
  ScrollingTwoGenreGames: 'ScrollingTwoGenreGames',
  AdsContainer: 'AdsContainer',
  GameDetailMasthead: 'GameDetailMasthead',
  UpdateBar: 'UpdateBar',
  SubNavigationBar: 'SubNavigationBar',
  GameShortDescription: 'GameShortDescription',
  GameDetailInformation: 'GameDetailInformation',
  KeyFeatureContainer: 'KeyFeatureContainer',
  GameDetailCharacter: 'GameDetailCharacter',
  HorizontalScrollingContainer: 'HorizontalScrollingContainer',
  CollectorCardContainer: 'CollectorCardContainer',
  ScrollingDLCContainer: 'ScrollingDLCContainer',
  ScrollingFreeCollectiblesContainer: 'ScrollingFreeCollectiblesContainer',
  MediaGalleryContainer: 'MediaGalleryContainer',
  GameSuggestionsCard: 'GameSuggestionsCard',
  ExploreMoreContainer: 'ExploreMoreContainer',
  ScrollingRelatedGamesContainer: 'ScrollingRelatedGamesContainer',
  PoliciesAndNotes: 'PoliciesAndNotes',
  AgeRatingPopup: 'AgeRatingPopup',
  ScrollingMediaGalleryContainer: 'ScrollingMediaGalleryContainer',
};
