export const BLOCK_WRAPPER = {
  paddingTop: {
    xs: '36px',
    md: '40px',
  },
  paddingBottom: {
    xs: '36px',
    md: '40px',
  },
};

export const BLOCK_WRAPPER_FIRST_SECTION = {
  paddingTop: {
    xs: '40px',
    md: '60px',
  },
};

export const BLOCK_WRAPPER_TEXT_FIRST = {
  paddingTop: {
    xs: '24px',
    md: '100px',
    xl: '160px',
  },
};

export const SECTION_ITEM = {
  spacing: {
    xs: '40px',
    md: '80px',
    // xl: '123px',
  },
};
