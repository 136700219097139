import {IHeaderArticle} from '@wearesection/bandai-react-components/organisms/HeaderArticle';
import Box from '@mui/material/Box';
import {GetRouter} from '~/utils';
import dynamic from 'next/dynamic';
const HeaderArticle = dynamic(
  () => import('@wearesection/bandai-react-components/organisms/HeaderArticle'),
  {ssr: false}
);
type IHeaderArticleExt = IHeaderArticle & {
  image?: string;
  label?: string;
  titleCopiedToClipboard?: string;
};

const adapter = (data: IHeaderArticleExt) => {
  const {locale} = GetRouter();
  let isShowWeibo = false;
  let isShowkakao = false;

  const newLocales = locale?.toLowerCase();
  if (
    newLocales === 'hk-tc' ||
    newLocales === 'hk-sc' ||
    newLocales === 'tw-tc' ||
    newLocales === 'tw-sc'
  ) {
    isShowWeibo = true;
  }
  if (newLocales === 'ko-kr') {
    isShowkakao = true;
  }

  const newData = {
    ...data,
    hideMasthead: !data?.hideMasthead,
    socialShareProps: {
      fbShare: data.fbShare,
      instaShare: data.instaShare,
      twShare: data.twShare,
      hrefShare: data.fbShare,
      weiboShare: isShowWeibo ? data.weiboShare : '',
      kakaoShare: isShowkakao ? data.kakaoShare : '',
      label: data?.label || 'Share this article',
      titleCopiedToClipboard: data?.titleCopiedToClipboard || '',
    },
  };
  return newData;
};

const IHeaderArticleOrganisms: React.FC<IHeaderArticleExt> = (props: any) => {
  const headerArticleProps = adapter(props);
  return (
    <Box
      sx={{
        paddingTop: {
          md: '80px',
          xs: '60px',
        },
      }}
    >
      <HeaderArticle {...headerArticleProps} />
    </Box>
  );
};

export default IHeaderArticleOrganisms;
