import Policy, {IPolicy} from '@wearesection/bandai-react-components/molecules/Policy';
import Box from '@mui/material/Box';
import React from 'react';
import {ComponentPropsAdapter} from '../../types';

type IScrollingDLCContainerExt = IPolicy & any & {};

const adapter: ComponentPropsAdapter<IScrollingDLCContainerExt, IPolicy> = (
  data: IScrollingDLCContainerExt
) => {
  return data;
};

const PolicyOrganisms: React.FC<IPolicy> = props => {
  const componentProps = adapter(props);

  return (
    <Box
      sx={{
        margin: {
          xs: '72px 0px',
          md: '80px 0px',
        },
        '& h2': {
          fontSize: '24px',
          lineHeight: '30px',
          fontWeight: '600',
        },
        '& li': {
          listStyleType: 'none',
          '&::before': {
            content: "'•'",
            color: '#737373',
            lineHeight: '22px',
            fontSize: '1.4rem',
            marginRight: '4px',
          },
        },
      }}
    >
      <Policy {...componentProps} />
    </Box>
  );
};

export default PolicyOrganisms;
