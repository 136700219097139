import React from 'react';

import * as Types from 'graphql/types';
import {ComponentsMap} from './constants';
import {usePageSetting} from '~/providers/page-context.provider';

type ComponentType = keyof typeof ComponentsMap;

const Global: React.FC<Types.Global> = props => {
  const {theme} = usePageSetting();
  const Component = ComponentsMap[props.type as ComponentType];
  return Component ? <Component {...props.configuration} theme={theme} /> : null;
};

export default Global;
