/* eslint-disable @typescript-eslint/no-explicit-any */
import _, {cloneDeep} from 'lodash';
import React from 'react';
import PageSeoStandard from '../seo/page-seo-standard';
import {ComponentContentTypes, ComponentContentTypesGamesListing} from './constants';
import {SectionRenderer} from './section-renderer';
import {themeInjection} from '~/components/renderer/theme-injection';
import HeaderArticle from '~/components/wrapper/organisms/header-article';
// import AgeRatingPopup from '~/components/wrapper/organisms/age-rating-pop-up';
import {withBlockWrapper} from './block-wrapper';
import dynamic from 'next/dynamic';

const AgeRatingPopup = dynamic(() => import('~/components/wrapper/organisms/age-rating-pop-up'), {
  ssr: false,
});

const Updatebar = dynamic(() => import('~/components/wrapper/organisms/update-bar'), {
  ssr: false,
});
const BuyGameContainer = dynamic(
  () => import('~/components/wrapper/organisms/buy-game-container'),
  {
    ssr: false,
  }
);
const GameDetailMasthead = dynamic(
  () => import('~/components/wrapper/organisms/game-detail-masthead'),
  {
    ssr: false,
  }
);

const ExploreMore = dynamic(() => import('~/components/wrapper/organisms/explore-more'), {
  ssr: false,
});

const GameShortDescription = dynamic(
  () => import('~/components/wrapper/organisms/game-short-description'),
  {
    ssr: false,
  }
);

const BodyArticle = dynamic(() => import('~/components/wrapper/organisms/body-article'), {
  ssr: false,
});

const SubNavigationBar = dynamic(
  () => import('~/components/wrapper/organisms/sub-navigation-bar'),
  {
    ssr: false,
  }
);

const GameDetailInformation = dynamic(
  () => import('~/components/wrapper/organisms/game-detail-information'),
  {
    ssr: false,
  }
);

type BlockRendererProps = {
  block: any;
  detailsReferralsUser?: any;
  indexBlock?: number;
  slug?: string;
  lengthBlock?: number;
};

// render UI
const BlockRenderer = ({
  block,
  detailsReferralsUser = {},
  indexBlock,
  slug,
  lengthBlock,
}: BlockRendererProps) => {
  if (Array.isArray(block)) {
    const lengthBlock = block?.length || 0;
    return (
      <>
        {block.map((b, index) => {
          return (
            <React.Fragment key={index}>
              <BlockRenderer
                key={`block-${index}`}
                block={b}
                indexBlock={index}
                slug={slug}
                lengthBlock={lengthBlock}
                detailsReferralsUser={detailsReferralsUser}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  }

  // get type name
  const contentTypeId = _.get(block, '__typename') as string;
  if (contentTypeId === 'Section') {
    const blockCopy = cloneDeep(block);
    return (
      <SectionRenderer
        block={blockCopy?.blocksCollection?.items || []}
        theme={blockCopy?.theme || {}}
        noPadding={blockCopy?.noPadding}
        sectionWrapperStyles={blockCopy?.sectionWrapperStyles}
        contentWrapperStyles={blockCopy?.contentWrapperStyles}
        internalName={blockCopy?.internalName}
        sectionId={blockCopy?.sectionId}
        indexBlock={indexBlock}
        lengthBlock={lengthBlock}
        slug={slug ?? '/'}
      />
    );
  }

  const Component = ContentTypeMap[contentTypeId];
  // check content type id
  if (!Component) {
    console.warn(`${contentTypeId} can not be handled`);
    return null;
  }

  const componentProps = {
    ...block,
    parent: block.parent,
  };
  return <Component key={`${contentTypeId}`} {...componentProps} />;
};

const ContentTypeMap = {
  [ComponentContentTypes.SeoStandard]: PageSeoStandard,
  [ComponentContentTypes.HeaderArticle]: themeInjection(HeaderArticle),
  [ComponentContentTypes.ExploreMoreOrganisms]: themeInjection(ExploreMore),
  [ComponentContentTypes.BuyGameContainer]: themeInjection(BuyGameContainer),
  [ComponentContentTypes.AgeRatingPopup]: themeInjection(AgeRatingPopup),
  [ComponentContentTypesGamesListing.GameDetailMasthead]: themeInjection(GameDetailMasthead),
  [ComponentContentTypesGamesListing.UpdateBar]: themeInjection(Updatebar),
  [ComponentContentTypesGamesListing.SubNavigationBar]: themeInjection(SubNavigationBar),
  [ComponentContentTypes.Section]: themeInjection(GameShortDescription),
  [ComponentContentTypesGamesListing.GameDetailInformation]: themeInjection(GameDetailInformation),

  [ComponentContentTypes.BodyArticle]: withBlockWrapper(
    themeInjection(BodyArticle),
    {},
    {
      fullWidth: true,
    }
  ),
};

export {BlockRenderer};
