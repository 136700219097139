import {withBlockWrapper} from '~/components/renderer/block-wrapper';

// import ContactUs from '~/components/wrapper/organisms/contact-us';
import ContactInfo from '~/components/wrapper/organisms/contact-info';

import AdsContainer from '~/components/wrapper/organisms/ads-container';
// import Masthead from '@wearesection/bandai-react-components/molecules/MastheadImage';
// import {themeInjection} from '~/components/renderer/theme-injection';
import Policy from '~/components/wrapper/organisms/Policy';
import dynamic from 'next/dynamic';

const ContactUs = dynamic(() => import('~/components/wrapper/organisms/contact-us'), {
  ssr: false,
});

//store
const EStoreAdvertise = dynamic(() => import('~/components/wrapper/organisms/store-adversite'), {
  ssr: false,
});

const EStoreSearchByCollectionOrganisms = dynamic(
  () => import('~/components/wrapper/organisms/store-home-page-search-collection'),
  {ssr: false}
);

export const ComponentsMap = {
  ContactUs,
  ContactInfo,
  AdsContainer: AdsContainer,
  Policy: Policy,
  Advertise: EStoreAdvertise,
  ShopByCollection: EStoreSearchByCollectionOrganisms,
  // Masthead: withBlockWrapper(Masthead, {}, {fullWidth: true}),
};
