import * as Types from 'graphql/types';
import Head from 'next/head';
import React from 'react';
import {GetRouter} from '~/utils';
/* eslint-disable  @typescript-eslint/no-explicit-any */
const PageSeoStandard: React.FC<Types.SeoStandard> = props => {
  const {
    seoTitle,
    seoDescription,
    seoRobots,
    seoImage,
    ogTitle,
    ogDescription,
    ogImage,
    seoKeywords,
    seoAuthor,
    seoPublisher,
  } = props;
  const {locale} = GetRouter();
  return (
    <Head>
      {seoTitle && <title>{seoTitle}</title>}
      {seoDescription && <meta name="description" content={seoDescription} />}
      {seoRobots && <meta name="robots" content={seoRobots} />}
      {seoImage && <link rel="image_src" href={seoImage}></link>}
      {ogTitle && (
        <>
          <meta property="og:title" content={ogTitle} />
          <meta name="twitter:title" content={ogTitle} />
        </>
      )}
      {ogDescription && (
        <>
          <meta property="og:description" content={ogDescription} />
          <meta name="twitter:description" content={ogDescription} />
        </>
      )}
      {ogImage && (
        <>
          <meta property="og:image" content={ogImage} />
          <meta name="twitter:image" content={ogImage} />
          <meta name="thumbnail" content={ogImage} />
        </>
      )}
      {seoKeywords && <meta name="keywords" content={seoKeywords} />}
      {seoAuthor && <meta name="author" content={seoAuthor} />}
      {seoPublisher && <meta name="publisher" content={seoPublisher} />}
      {`${locale}`.toLowerCase() === 'ko-kr' && (
        <meta name="naver-site-verification" content="08921950e9a99ecdd6fed2261fa5f7861f98b2ed" />
      )}
    </Head>
  );
};

export default PageSeoStandard;
