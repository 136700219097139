import Container from '@wearesection/bandai-react-components/atoms/Container';
import PoliciesAndNotes, {
  IPoliciesAndNotes,
} from '@wearesection/bandai-react-components/organisms/PoliciesAndNotes';
import React from 'react';
import {ComponentPropsAdapter} from '../../types';
import {Box} from '@mui/material';
import {Game} from '~/graphql/types';
import {GetRouter} from '~/utils';
type IPoliciesAndNotesExt = IPoliciesAndNotes & Game & {};

const adapter: ComponentPropsAdapter<IPoliciesAndNotesExt, IPoliciesAndNotes> = data => {
  return {
    title: data?.title ?? '',
    label: data?.label ?? '',
    labelHref: data?.labelHref,
    href: data.href ?? '/',
    description: data?.description ?? '',
  };
};

const PoliciesAndNotesOrganisms: React.FC<IPoliciesAndNotesExt> = props => {
  const {pathname, locale} = GetRouter();
  const ComponentProps = adapter({...props, href: `/${locale}` + props.href});

  const PADDING_COMPONENT: any = {
    '/games': {
      padding: {
        xs: '48px 0px 64px 0px',
        lg: '40px 0px',
      },
    },
  };
  return (
    <Box sx={PADDING_COMPONENT[pathname] ?? PADDING_COMPONENT['/games']}>
      <Container
        sx={{
          padding: {
            xs: '0 0 0 0',
            md: '0 24px 0 24px',
          },
        }}
      >
        <PoliciesAndNotes {...ComponentProps} />
      </Container>
    </Box>
  );
};

export default PoliciesAndNotesOrganisms;
