import React from 'react';
import {usePageSetting} from '~/providers/page-context.provider';

type AdditionProps = {
  theme?: string;
};

export function themeInjection<T>(WrappedComponent: React.ComponentType<T>) {
  const ComponentWithTheme = (props: T & AdditionProps) => {
    const {theme} = usePageSetting();
    return <WrappedComponent {...props} theme={theme} />;
  };

  return ComponentWithTheme;
}
