import React, {useMemo} from 'react';
import Box from '@mui/material/Box';
import AdsContainer, {
  IAdsContainerProps,
} from '@wearesection/bandai-react-components/organisms/AdsContainer';
import {PLACEHOLDER_IMG} from '~/constants';
import Container from '@wearesection/bandai-react-components/atoms/Container';

const adapter = (data: IAdsContainerProps) => {
  return {
    img: data?.img ?? PLACEHOLDER_IMG,
    alt: data?.alt,
    title: data?.title,
    description: data?.description,
    href: data?.href,
  };
};

const AdsContainerOrganisms: React.FC<IAdsContainerProps & any> = (props: any) => {
  const ComponentProps = useMemo(() => adapter(props), [props]);
  return (
    <Box
      sx={{
        paddingTop: {
          xs: '72px',
          lg: '80px',
        },
        paddingBottom: {
          xs: '72px',
          lg: '80px',
        },
      }}
    >
      <Container>
        <AdsContainer {...ComponentProps} id={props?.id || ''} />
      </Container>
    </Box>
  );
};

export default AdsContainerOrganisms;
