/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box} from '@mui/material';
import _, {isObject} from 'lodash';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import {themeInjection} from '~/components/renderer/theme-injection';

import {SECTION_ITEM} from '../../styles/styles.constant';
// contentful
import * as Types from '~/graphql/types';
import Global from '../wrapper/organisms/global';
import {withBlockWrapper} from './block-wrapper';
import {
  ComponentContentTypes,
  ComponentContentTypesBlogListing,
  ComponentContentTypesGamesListing,
  ComponentContentTypesNewsListing,
  ComponentContentTypesSupportFAQ,
} from './constants';

// store
const StoreHomepageMashTheadOrganisms = dynamic(
  () => import('~/components/wrapper/organisms/store-home-page-mash-thead'),
  {ssr: false}
);

const HomepageBestsellerHomeOrganisms = dynamic(
  () => import('~/components/wrapper/organisms/store-home-page-bestseller'),
  {ssr: false}
);

const HomepageSaleOrganisms = dynamic(
  () => import('~/components/wrapper/organisms/store-home-page-sale'),
  {ssr: false}
);

const StoreProductDetailRecentlyOrganisms = dynamic(
  () => import('~/components/wrapper/organisms/store-home-page-pre-order'),
  {ssr: false}
);

const StoreNewArrivalHomeOrganisms = dynamic(
  () => import('~/components/wrapper/organisms/store-home-page-new-arrivals'),
  {ssr: false}
);

const EStoreGameShopByPlatform = dynamic(
  () => import('../wrapper/organisms/store-game-shop-by-platform'),
  {
    ssr: false,
  }
);
//end

const ScrollingFeaturedCard = dynamic(
  () => import('../wrapper/organisms/scrolling-featured-card'),
  {ssr: true}
);
const ScrollingThumbnailCard = dynamic(
  () => import('../wrapper/organisms/scrolling-thumbnail-card'),
  {ssr: false}
);
const ScrollingThumbnailCardPublicDate = dynamic(
  () => import('../wrapper/organisms/scrolling-thumbnail-card-public-date'),
  {ssr: false}
);
const ScrollingNewsFilterOrganisms = dynamic(
  () => import('../wrapper/organisms/scrolling-news-filter'),
  {ssr: false}
);
const ScrollingFeaturedGenreGameContainer = dynamic(
  () => import('../wrapper/organisms/scrolling-featured-genre-game-container'),
  {ssr: false}
);
const ScrollingPopularGameContainer = dynamic(
  () => import('../wrapper/organisms/scrolling-popular-game-container'),
  {ssr: false}
);
const ScrollingTwoGenreGames = dynamic(
  () => import('../wrapper/organisms/scrolling-two-genre-games'),
  {ssr: false}
);
const GameShortDescription = dynamic(() => import('../wrapper/organisms/game-short-description'), {
  ssr: false,
});

// import AgeRatingPopup from '~/components/wrapper/organisms/age-rating-pop-up';

const AgeRatingPopup = dynamic(() => import('~/components/wrapper/organisms/age-rating-pop-up'), {
  ssr: true,
});
import GameSuggestionsCard from '../wrapper/organisms/game-suggestions-card';
import PoliciesAndNotes from '../wrapper/organisms/policies-and-notes';
import SubNavigationBar from '../wrapper/organisms/sub-navigation-bar';
const KeyFeatureContainer = dynamic(() => import('../wrapper/organisms/key-feature-container'), {
  ssr: false,
});
const GameDetailCharacter = dynamic(() => import('../wrapper/organisms/game-detail-character'), {
  ssr: false,
});
const HorizontalScrollingContainer = dynamic(
  () => import('../wrapper/organisms/horizontal-scrolling-container'),
  {ssr: false}
);
const CollectorCardContainer = dynamic(
  () => import('../wrapper/organisms/collector-card-container'),
  {ssr: false}
);
const ScrollingDLCContainer = dynamic(
  () => import('../wrapper/organisms/scrolling-dlc-container'),
  {ssr: false}
);
const ScrollingFreeCollectiblesContainer = dynamic(
  () => import('../wrapper/organisms/scrolling-free-collectibles-container'),
  {ssr: false}
);
const MediaGalleryContainer = dynamic(
  () => import('../wrapper/organisms/media-gallery-container'),
  {ssr: false}
);
const ExploreMoreContainer = dynamic(() => import('../wrapper/organisms/explore-more-container'), {
  ssr: false,
});
const MastheadImageOriginal = dynamic(() => import('../wrapper/organisms/masthead-image'), {
  ssr: false,
});
const MessageCard = dynamic(() => import('../wrapper/organisms/message-card'), {ssr: false});
const LocateUs = dynamic(() => import('../wrapper/organisms/locate-us'), {ssr: false});

const JobOpportunity = dynamic(() => import('../wrapper/organisms/job-hire'), {ssr: false});

const ScrollingRelatedGamesContainer = dynamic(
  () => import('../wrapper/organisms/scrolling-related-games-container'),
  {ssr: false}
);
const UpdateBar = dynamic(() => import('../wrapper/organisms/update-bar'), {ssr: false});
const GameDetailInformation = dynamic(
  () => import('../wrapper/organisms/game-detail-information'),
  {ssr: false}
);
const HomepageMashTheadOrganisms = dynamic(
  () => import('~/components/wrapper/organisms/home-page-mash-thead'),
  {ssr: false}
);
const HomepageExploreNewUniverseOrganisms = dynamic(
  () => import('~/components/wrapper/organisms/home-page-explore-new-universe'),
  {ssr: false}
);
const ScrollingGoodToKnow = dynamic(
  () => import('~/components/wrapper/organisms/scrolling-good-to-know'),
  {ssr: false}
);
const GameDetailMasthead = dynamic(
  () => import('~/components/wrapper/organisms/game-detail-masthead'),
  {ssr: true}
);

// import GameDetailMasthead  import '~/components/wrapper/organisms/game-detail-masthead';
const ScrollingMediaGalleryContainer = dynamic(
  () => import('~/components/wrapper/organisms/scrolling-media-gallery-container'),
  {ssr: false}
);
const HorizontalScrollingContainerMediaGalleryOrganisms = dynamic(
  () =>
    import(
      '~/components/wrapper/organisms/horizontal-scrolling-container-media-gallery/horizontal-scrolling-container-media-gallery'
    )
);

import AdsContainer from '~/components/wrapper/organisms/ads-container';

const SupportFAQ = dynamic(() => import('~/components/wrapper/organisms/support-faq'), {
  ssr: false,
});

export type SectionRendererProps = {
  block: any;
  theme: Types.Theme;
  noPadding: boolean;
  internalName?: string;
  sectionWrapperStyles?: {
    [key: string]: any;
  };
  contentWrapperStyles?: {
    [key: string]: any;
  };
  indexBlock?: number;
  sectionId?: string;
  slug: string;
  lengthBlock?: number;
};

// render UI
export const SectionRenderer = ({
  block,
  theme: sectionTheme,
  noPadding,
  internalName,
  sectionWrapperStyles,
  contentWrapperStyles,
  indexBlock,
  sectionId,
  slug,
  lengthBlock,
}: SectionRendererProps) => {
  // const {theme: pageTheme, themeOption} = usePageSetting();
  const {locale = ''} = useRouter();
  if (Array.isArray(block)) {
    const css = isObject(sectionWrapperStyles) ? sectionWrapperStyles : {};
    return (
      <Box
        sx={{
          // paddingTop: noPadding
          //   ? '0px'
          //   : indexBlock === 0
          //   ? BLOCK_WRAPPER_FIRST_SECTION.paddingTop
          //   : BLOCK_WRAPPER.paddingTop,
          // paddingBottom: noPadding ? '0px' : BLOCK_WRAPPER.paddingBottom,
          backgroundColor: `${sectionTheme?.codeId}  !important`,
          '& > div:not(:last-of-type)': {
            marginBottom: {...SECTION_ITEM.spacing},
          },
          ...css,
          '&>div': {
            paddingTop: '0px',
            paddingBottom: '0px',
          },
        }}
        className={sectionId ?? ''}
        id={internalName ? internalName.toLowerCase().split(' ').join('') : ''}
      >
        {block.map((b, index) => (
          <SectionRenderer
            key={`block-${index}`}
            slug={slug}
            block={b}
            indexBlock={indexBlock}
            theme={sectionTheme}
            noPadding={noPadding}
            internalName={internalName}
            lengthBlock={lengthBlock}
            sectionId={sectionId}
            sectionWrapperStyles={sectionWrapperStyles}
            contentWrapperStyles={contentWrapperStyles}
          />
        ))}
      </Box>
    );
  }

  // get type name
  const contentTypeId = _.get(block, '__typename') as string;
  if (contentTypeId === 'NewsListing') {
    const contentType = _.get(block, 'type') as string;
    const Component = ContentTypeMapNewsListing[contentType];
    const componentProps = {
      ...block,
      parent: block.parent,
      indexBlock: indexBlock,
      lengthBlock: lengthBlock,
      noPadding: noPadding,
      contentWrapperStyles,
      locale: locale,
      themeSection: sectionTheme?.name,
      slug: slug,
    };
    if (!Component) {
      console.warn(`${contentTypeId} can not be handled`);
      return null;
    }
    return <Component key={`${contentTypeId}`} {...componentProps} />;
  }

  if (contentTypeId === 'BlogListing') {
    const contentType = _.get(block, 'type') as string;
    const Component = ContentTypeMapBlogListing[contentType];
    const componentProps = {
      ...block,
      parent: block.parent,
      indexBlock: indexBlock,
      lengthBlock: lengthBlock,
      noPadding: noPadding,
      contentWrapperStyles,
      locale: locale,
      themeSection: sectionTheme?.name,
      slug: slug,
    };
    if (!Component) {
      console.warn(`${contentTypeId} can not be handled`);
      return null;
    }
    return <Component key={`${contentTypeId}`} {...componentProps} />;
  }

  if (contentTypeId === 'GamesListing') {
    const contentType = _.get(block, 'type') as string;
    const Component = ContentTypeMap[contentType];
    const componentProps = {
      ...block,
      parent: block.parent,
      contentWrapperStyles,
      noPadding: noPadding,
      lengthBlock: lengthBlock,
      indexBlock: indexBlock,
      locale: locale,
      themeSection: sectionTheme?.name,
    };
    if (!Component) {
      console.warn(`${contentTypeId} can not be handled`);
      return null;
    }

    return <Component key={`${contentTypeId}`} {...componentProps} />;
  }
  const Component = ContentTypeMap[contentTypeId];

  // check content type id
  const componentProps = {
    ...block,
    parent: block.parent,
    indexBlock: indexBlock,
    lengthBlock: lengthBlock,
    contentWrapperStyles,
    noPadding: noPadding,
    locale: locale,
    themeSection: sectionTheme?.name,
    slug: slug,
  };

  if (!Component) {
    console.warn(`${contentTypeId} can not be handled`);
    return null;
  }
  return <Component key={`${contentTypeId}`} {...componentProps} />;
};

const sx = {
  paddingTop: 0,
  paddingBottom: 0,
};

const ContentTypeMap = {
  [ComponentContentTypes.Global]: withBlockWrapper(themeInjection(Global), sx),
  [ComponentContentTypes.MastheadImage]: withBlockWrapper(
    themeInjection(MastheadImageOriginal),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesSupportFAQ.SupportFaq]: withBlockWrapper(themeInjection(SupportFAQ), {}),
  [ComponentContentTypes.HomePageGamesListing]: withBlockWrapper(
    themeInjection(ExploreMoreContainer),
    {}
  ),
  [ComponentContentTypes.SuggestionCard]: withBlockWrapper(themeInjection(GameSuggestionsCard), {}),
  [ComponentContentTypes.MessageCard]: withBlockWrapper(themeInjection(MessageCard), {}),
  [ComponentContentTypes.LocateUs]: withBlockWrapper(
    themeInjection(LocateUs),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypes.JobOpportunity]: withBlockWrapper(themeInjection(JobOpportunity)),
  [ComponentContentTypesGamesListing.ScrollingNewsFilter]: withBlockWrapper(
    themeInjection(ScrollingNewsFilterOrganisms),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.ScrollingFeaturedGenreGameContainer]: withBlockWrapper(
    themeInjection(ScrollingFeaturedGenreGameContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.ScrollingPopularGameContainer]: withBlockWrapper(
    themeInjection(ScrollingPopularGameContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.ScrollingTwoGenreGames]: withBlockWrapper(
    themeInjection(ScrollingTwoGenreGames),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.AdsContainer]: withBlockWrapper(
    themeInjection(AdsContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.FeaturedCardCarousel]: withBlockWrapper(
    themeInjection(ScrollingFeaturedCard),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.ThumbnailCardCarousel]: withBlockWrapper(
    themeInjection(ScrollingThumbnailCard),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.ThumbnailCardWithPublicDate]: withBlockWrapper(
    themeInjection(ScrollingThumbnailCardPublicDate),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.GameShortDescription]: withBlockWrapper(
    themeInjection(GameShortDescription),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypes.Mashthead]: withBlockWrapper(
    themeInjection(HomepageMashTheadOrganisms),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.SubNavigationBar]: withBlockWrapper(
    themeInjection(SubNavigationBar),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.GameShortDescription]: withBlockWrapper(
    themeInjection(GameShortDescription),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypes.ExploreNewUniverse]: withBlockWrapper(
    themeInjection(HomepageExploreNewUniverseOrganisms),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypes.HomePageNewListing]: withBlockWrapper(
    themeInjection(ScrollingFreeCollectiblesContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.SubNavigationBar]: withBlockWrapper(
    themeInjection(SubNavigationBar),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.KeyFeatureContainer]: withBlockWrapper(
    themeInjection(KeyFeatureContainer),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypes.HorizontalScrollingContainerMediaGallery]: withBlockWrapper(
    themeInjection(HorizontalScrollingContainerMediaGalleryOrganisms),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.KeyFeatureContainer]: withBlockWrapper(
    themeInjection(KeyFeatureContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypes.MediaGallery]: withBlockWrapper(
    themeInjection(GameSuggestionsCard),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.GameDetailCharacter]: withBlockWrapper(
    themeInjection(GameDetailCharacter),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.HorizontalScrollingContainer]: withBlockWrapper(
    themeInjection(HorizontalScrollingContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.CollectorCardContainer]: withBlockWrapper(
    themeInjection(CollectorCardContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.ScrollingDLCContainer]: withBlockWrapper(
    themeInjection(ScrollingDLCContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.ScrollingFreeCollectiblesContainer]: withBlockWrapper(
    themeInjection(ScrollingFreeCollectiblesContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.MediaGalleryContainer]: withBlockWrapper(
    themeInjection(MediaGalleryContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.GameSuggestionsCard]: withBlockWrapper(
    themeInjection(GameSuggestionsCard),
    {}
  ),
  [ComponentContentTypesGamesListing.ExploreMoreContainer]: withBlockWrapper(
    themeInjection(ExploreMoreContainer)
  ),
  [ComponentContentTypesGamesListing.ScrollingRelatedGamesContainer]: withBlockWrapper(
    themeInjection(ScrollingRelatedGamesContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.PoliciesAndNotes]: withBlockWrapper(
    themeInjection(PoliciesAndNotes),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.UpdateBar]: withBlockWrapper(
    themeInjection(UpdateBar),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.GameDetailInformation]: withBlockWrapper(
    themeInjection(GameDetailInformation),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.UpdateBar]: withBlockWrapper(themeInjection(UpdateBar), sx, {
    fullWidth: true,
  }),
  [ComponentContentTypesGamesListing.AgeRatingPopup]: withBlockWrapper(
    themeInjection(AgeRatingPopup),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.GameDetailMasthead]: withBlockWrapper(
    themeInjection(GameDetailMasthead),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.ScrollingMediaGalleryContainer]: withBlockWrapper(
    themeInjection(ScrollingMediaGalleryContainer),
    sx,
    {
      fullWidth: true,
    }
  ),
  // store
  [ComponentContentTypesGamesListing.MashtheadStore]: withBlockWrapper(
    themeInjection(StoreHomepageMashTheadOrganisms),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.EStoreBestsellerHome]: withBlockWrapper(
    themeInjection(HomepageBestsellerHomeOrganisms),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.SaleEvent]: withBlockWrapper(
    themeInjection(HomepageSaleOrganisms),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.EStoreProductDetailRecently]: withBlockWrapper(
    themeInjection(StoreProductDetailRecentlyOrganisms),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.EStoreNewArrivalHome]: withBlockWrapper(
    themeInjection(StoreNewArrivalHomeOrganisms),
    sx,
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesGamesListing.PlatformListing]: withBlockWrapper(
    themeInjection(EStoreGameShopByPlatform),
    sx,
    {
      fullWidth: true,
    }
  ),
};

const ContentTypeMapNewsListing = {
  [ComponentContentTypesNewsListing.ThumbnailCardCarousel]: withBlockWrapper(
    themeInjection(ScrollingThumbnailCard),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesNewsListing.FeaturedCardCarousel]: withBlockWrapper(
    themeInjection(ScrollingFeaturedCard),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesNewsListing.ThumbnailCardCarousel]: withBlockWrapper(
    themeInjection(ScrollingThumbnailCard),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesNewsListing.ThumbnailCardWithPublicDate]: withBlockWrapper(
    themeInjection(ScrollingThumbnailCardPublicDate),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesNewsListing.ScrollingFeaturedGenreGameContainer]: withBlockWrapper(
    themeInjection(ScrollingFeaturedGenreGameContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesNewsListing.ScrollingPopularGameContainer]: withBlockWrapper(
    themeInjection(ScrollingPopularGameContainer),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesNewsListing.ScrollingTwoGenreGames]: withBlockWrapper(
    themeInjection(ScrollingTwoGenreGames),
    {},
    {
      fullWidth: true,
    }
  ),
  [ComponentContentTypesNewsListing.AdsContainer]: withBlockWrapper(
    themeInjection(AdsContainer),
    {},
    {
      fullWidth: true,
    }
  ),
};

const ContentTypeMapBlogListing = {
  // [ComponentContentTypesBlogListing.BlogFilter]: withBlockWrapper(themeInjection(BlogCategory)),
  [ComponentContentTypesBlogListing.BlogListGoodToKnow]: withBlockWrapper(
    themeInjection(ScrollingGoodToKnow),
    {},
    {
      fullWidth: true,
    }
  ),
};
