import {Box} from '@mui/material';
import React from 'react';
import {IGameSuggestionsCard} from '@wearesection/bandai-react-components/organisms/GameSuggestionsCard';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import RenderNextImage from '../../atoms/render-next-image';
import {PLACEHOLDER_IMG} from '~/constants';
import {
  convertPlatformToIcon,
  convertToSlug,
  GetRouter,
  handleFilterTags,
  handleRenderPriceHomePageMashThead,
  handleReturnCurrency,
} from '~/utils';
import dynamic from 'next/dynamic';
import {handleReturnLocaleStore} from '../home-page-mash-thead/home-page-mash-thead';
const GameSuggestionsCard = dynamic(
  () => import('@wearesection/bandai-react-components/organisms/GameSuggestionsCard'),
  {ssr: false}
);
type bcProduct = {
  name: string;
  prices: {
    price: {currencyCode: string; value: number};
  };
  slugGame: string;
};

type IGameSuggestionsCardExt = IGameSuggestionsCard & bcProduct;
const adapter = (data: IGameSuggestionsCardExt & any) => {
  const bcProductReference = data?.bcProductReference;
  const BCNameGame = bcProductReference?.title || '';

  return {
    title: data?.name || '',
    mainImage: (data as any)?.gamesFilterThumbnailImage ?? PLACEHOLDER_IMG,
    mainImageNext: (
      <Box
        sx={{
          width: '100%',
          '& span': {
            width: '100% !important',
          },
        }}
      >
        <RenderNextImage
          src={(data as any)?.gamesFilterThumbnailImage ?? PLACEHOLDER_IMG}
          width={457}
          height={257}
          alt={data?.name || ''}
        />
      </Box>
    ),
    description: documentToReactComponents((data as any)?.description?.json) || '',
    buttonProps: {
      variant: 'secondaryBlack',
      disabled: !BCNameGame,
      label: bcProductReference?.isPreOrder
        ? data?.dataTranslation['estore-home-page-title-button']
        : data?.dataTranslation['product-buy'],
      externalLink: BCNameGame
        ? `${process.env.NEXT_PUBLIC_STORE_SITE_URL}${handleReturnLocaleStore(
            data?.locale || ''
          )}/store/games/${convertToSlug(BCNameGame)}`
        : '',
    },
    tags:
      handleFilterTags((data as any)?.platform as [])?.map((item: any) => ({
        color: '#737373',
        title: React.createElement(convertPlatformToIcon(item)),
        disabled: false,
      })) || [],
    priceProps: {
      label:
        `${data?.locale}`.toLowerCase() === 'ko-kr'
          ? ''
          : BCNameGame
          ? data?.dataTranslation['product-from']
          : '',
      currency: '',
      // `${data?.locale}`.toLowerCase() === 'ko-kr'
      //   ? ''
      //   : BCNameGame
      //   ? handleReturnCurrency(data?.locale)
      //   : '',
      amount: bcProductReference?.price
        ? `${
            `${data?.locale}`.toLowerCase() !== 'ko-kr' ? handleReturnCurrency(data?.locale) : ''
          }${handleRenderPriceHomePageMashThead(bcProductReference?.price, data?.locale)}${
            `${data?.locale}`.toLowerCase() === 'ko-kr'
              ? `${handleReturnCurrency(data?.locale)} 부터`
              : ''
          }`
        : '',
      noFormat: 1,
    },
    isComingSoon: !BCNameGame ? {label: data?.dataTranslation['coming-soon']} : '',
    isBlogLayout: data?.isBlogLayout ?? false,
    linkHref: BCNameGame
      ? `${process.env.NEXT_PUBLIC_STORE_SITE_URL}${handleReturnLocaleStore(
          data?.locale || ''
        )}/store/games/${convertToSlug(BCNameGame)}`
      : '',
    onlyButton: data?.isBlogLayout ? true : false,
    buttonPropsLearnMore: data?.buttonPropsLearnMore,
  };
};

const homePageSuggestionCard = (data: any): IGameSuggestionsCard => {
  return {
    title: data?.title || '',
    mainImage: data?.thumbnail?.url ?? PLACEHOLDER_IMG,
    tags: [],
    mainImageNext: (
      <Box
        sx={{
          width: '100%',
          '& span': {
            width: '100% !important',
          },
        }}
      >
        <RenderNextImage
          src={data?.thumbnail?.url ?? PLACEHOLDER_IMG}
          width={457}
          height={257}
          alt={data?.title || ''}
        />
      </Box>
    ),
    description: data?.description,
    buttonProps: {
      variant: 'primaryDark',
      disabled: false,
      label: data?.titleUrl || 'Shop now',
      externalLink: `${data?.url}`,
    },
    linkHref: `${data?.url}`,
    onlyButton: true,
  };
};

const handleTypeName = (props: any) => {
  const typeName: any = {
    SuggestionCard: homePageSuggestionCard(props),
  };
  return typeName[props.__typename] || adapter(props);
};

const GameSuggestionsCardOrganisms: React.FC<IGameSuggestionsCardExt> = (props: any) => {
  const ComponentProps = handleTypeName(props);
  const {pathname} = GetRouter();

  const returnPadding = (slug: string): any => {
    switch (slug) {
      case '/[[...slug]]':
      case '/':
        return {
          paddingTop: {
            xs: '72px',
            lg: '40px',
          },
          paddingBottom: {
            xs: '72px',
            lg: '40px',
          },

          '& .wrapper-btn-cart': {
            width: '100%',
            display: 'flex',
            marginTop: '0px',
            justifyContent: {
              xs: 'center',
              lg: 'flex-start',
            },
          },
        };
      case '/games/[slug]':
      case '/games':
        return {
          paddingTop: {
            xs: '72px',
            lg: '40px',
          },
          paddingBottom: {
            xs: '72px',
            lg: '40px',
          },
        };

      case '/news/[slug]':
      case '/news':
        return {
          paddingTop: {
            xs: '72px',
            lg: '40px',
          },
          paddingBottom: {
            xs: '72px',
            lg: '40px',
          },
        };
      case '/blog/[slug]':
      case '/blog':
        return {
          paddingTop: {
            xs: '72px',
            lg: '40px',
          },
          paddingBottom: {
            xs: '72px',
            lg: '40px',
          },
        };
      default:
        return {
          paddingTop: {
            xs: '72px',
            lg: '40px',
          },
          paddingBottom: {
            xs: '72px',
            lg: '40px',
          },

          '& .wrapper-btn-cart': {
            width: '100%',
            display: 'flex',
            justifyContent: {
              xs: 'center',
              lg: 'flex-start',
            },
          },
        };
    }
  };

  return (
    <Box sx={returnPadding(pathname)}>
      <GameSuggestionsCard {...ComponentProps} id={props?.id || ''} />
    </Box>
  );
};

export default GameSuggestionsCardOrganisms;
