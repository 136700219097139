import React, {useEffect, useMemo, useRef} from 'react';
import {ISubNavigationBarProps} from '@wearesection/bandai-react-components/organisms/SubNavigationBar';
import Box from '@mui/material/Box';
import {ComponentPropsAdapter} from '../../types';
import {convertToSlug, GetRouter} from '~/utils';
import dynamic from 'next/dynamic';
import {usePageSetting} from '~/providers/page-context.provider';
import {handleReturnLocaleStore} from '../home-page-mash-thead/home-page-mash-thead';
const SubNavigationBar = dynamic(
  () => import('@wearesection/bandai-react-components/organisms/SubNavigationBar'),
  {ssr: false}
);
type ISubNavigationBarOrganisms = ISubNavigationBarProps & any & {};

const handleReturnListItemRender = (data: any) => {
  const listItemRender: any[] = [];
  data?.listItemRender?.length &&
    data?.listItemRender.map((item: any) => {
      if (item?.id) {
        listItemRender.push(item.id);
      }
      return null;
    });
  return listItemRender;
};

const adapter = (data: ISubNavigationBarOrganisms) => {
  const {locale} = GetRouter();

  const listItemRender: any[] = handleReturnListItemRender(data);
  const lisSubNavi = data.listNavigation;
  const listNavigation: any[] = [];
  if (lisSubNavi?.length && listItemRender.length) {
    lisSubNavi.map((item: any) => {
      if (listItemRender.includes(item?.value)) {
        listNavigation.push(item);
      }
    });
  }
  const bcProductReference = data?.bcProductReference;
  const BCNameGame = bcProductReference?.title || '';
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {globalData} = usePageSetting();
  const dataTranslation = globalData?.dataTranslation?.[0] ?? {};
  const isLocalDisableBtnBuy = false;
  //  ['ko-kr'].includes((locale as string)?.toLowerCase());
  return {
    listNavigation: listNavigation,
    onBuy: () => {},
    titleBtn: isLocalDisableBtnBuy
      ? ''
      : BCNameGame
      ? bcProductReference?.isPreOrder
        ? data?.dataTranslation['estore-home-page-title-button']
        : data?.dataTranslation['product-buy']
      : '',
    externalLink: isLocalDisableBtnBuy
      ? ''
      : BCNameGame
      ? `${process.env.NEXT_PUBLIC_STORE_SITE_URL}${handleReturnLocaleStore(
          locale || ''
        )}/store/games/${convertToSlug(BCNameGame)}`
      : '',
    onSelectItem: () => {},
    isComingSoon: !BCNameGame ? {label: dataTranslation['coming-soon']} : '',
  };
};

const SubNavigationBarOrganisms: React.FC<ISubNavigationBarProps> = props => {
  const componentProps: any = adapter(props);
  const refTimeOut = useRef<any>();
  useEffect(() => {
    return () => {
      window.clearTimeout(refTimeOut.current);
    };
  }, []);
  const handleUpdateUrl = (id: string) => {
    if (id) {
      if (typeof window !== 'undefined') {
        if (refTimeOut.current) {
          window.clearTimeout(refTimeOut.current);
        }
        refTimeOut.current = setTimeout(() => {
          refTimeOut.current = undefined;
          window.history.replaceState('', '', `?id=${id}`);
        }, 300);
      }
    }
  };
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: {
          xs: '22px',
          md: '24px',
        },
      }}
    >
      <SubNavigationBar {...componentProps} onSelectIdContent={handleUpdateUrl} />
    </Box>
  );
};

export default SubNavigationBarOrganisms;
