import ContactInfo, {
  IContactInfoProps,
} from '@wearesection/bandai-react-components/organisms/ContactInfo';
import {Box} from '@mui/material';
import React from 'react';

function ContactInfoOrganisms(props: IContactInfoProps) {
  return (
    <Box
      sx={{
        paddingBottom: '40px',

        '& .field_item': {
          marginTop: {
            xs: '32px',
            md: '36px',
          },
        },
      }}
    >
      <ContactInfo {...props} />
    </Box>
  );
}

export default ContactInfoOrganisms;
