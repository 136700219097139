import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@wearesection/bandai-react-components/atoms/Container';
import { SxProps } from '@mui/system';
import theme from '@wearesection/bandai-react-components/theme';
import {BLOCK_WRAPPER, BLOCK_WRAPPER_FIRST_SECTION} from '~/styles/styles.constant';

interface Options {
  fullWidth?: boolean;
}

type BlockWrapperExtraType = {
  contentWrapperStyle: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
};

export function withBlockWrapper<T>(
  WrappedComponent: React.ComponentType<T>,
  sx: SxProps<typeof theme> = {},
  options: Options = {}
) {
  const ComponentWithBlockWrapper = (props: T & BlockWrapperExtraType & any) => {
    const {fullWidth = false} = options;
    const {contentWrapperStyle = {}} = props;

    const [fullWidthState, setFullWidthState] = useState(false);
    useEffect(() => {
      setFullWidthState(fullWidth);
    }, [fullWidth]);

    return (
      <Box
        sx={{
          ...sx,
        }}
      >
        {fullWidthState ? (
          <WrappedComponent {...(props as T)} />
        ) : (
          <Container sx={{...contentWrapperStyle}}>
            <WrappedComponent {...(props as T)} />
          </Container>
        )}
      </Box>
    );
  };

  return ComponentWithBlockWrapper;
}
